import React, { useState, useEffect, useRef, LegacyRef } from "react";
import dynamic from "next/dynamic";

// interfaces
import { HomeMainProps } from "./HomeMain.interface";

// components
import HomeMainCarousel from "./components/HomeMainCarousel";
import ButtonHomeMain from "./components/HomeMainButton";
import ArrowRightLong from "@/components/icons/ArrowRightLong";
const LocationsEvents = dynamic(() => import("./components/LocationsEvents"), { ssr: false });
const HomePageBrands = dynamic(() => import("@/components/HomeBrands"), { ssr: false });

// helpers
import { useDimension } from "@/hooks/useDimensions";
import useUserProfile from "@/hooks/useUserProfile";

// assets
import styles from "@/assets/styles/containers/home-main.module.scss";

const HomeMain = (props: HomeMainProps) => {
  const {
    spacesCount,
    spaceImages,
    placeImages,
    typesOfLocations,
    typesOfEvents,
    brandLogos,
    containerRef,
    brandRef,
    isDesktop,
    scrollToContact
  } = props;

  const [textHeight, setTextHeight] = useState<number | undefined>(200);
  const locEventsMaxTextHeightRef = useRef<HTMLElement>(null);

  const [width] = useDimension();

  const { isAuthorized } = useUserProfile();


  // Effects
  useEffect(() => {
    if (width <= 1281) {
      setTimeout(() => {
        const { height } = locEventsMaxTextHeightRef.current?.getBoundingClientRect() || {};
        setTextHeight(height);
      }, 500);
    }
  }, [width]);

  return (
    <section className={styles["locl-home-main"]} ref={containerRef} data-testid="locl-home-main">
      {!!spaceImages.length && !!placeImages.length && (
        <h2 className={styles["locl-home-main__title"]} data-testid="locl-home-main-title">Are you looking for...</h2>
      )}
      <div className={styles["locl-home-main__looking-for"]}>
        {!!spaceImages.length && (
          <div className={styles["locl-home-main__looking-for__single-item"]} data-testid="locl-home-main-space-info">
            <HomeMainCarousel
              images={spaceImages || []}
              isDesktop={isDesktop}
              wrapperName={styles["locl-home-main__carousel"]}
            />
            <h3 className={styles["locl-home-main__looking-for__single-item__title"]}>
              lo:live - our digital <br /> marketplace
            </h3>
            <p
              style={{ height: isDesktop ? textHeight : "auto" }}
              className={styles["locl-home-main__looking-for__single-item__text"]}
            >
              {isDesktop
                ? `Get immediate access to over ${spacesCount} high footfall brand activation spaces via the largest platform of prime brand spaces worldwide. You can search, plan and book online. SIMPLE - FAST - DIRECT. Alternatively brief our team of space experts and we will select a solution or create a campaign for you.`
                : "From retail to travel, entertainment or leisure, our international marketplace gives you immediate access to over 1,500 spaces."}
            </p>
            <div className={styles["locl-home-main__looking-for__single-item__button-row"]}>
              <ButtonHomeMain
                to="/lolive"
                target="_blank"
                rel="noreferrer"
                onClick={(e: React.MouseEvent) => {
                  if (isAuthorized) {
                    e.preventDefault();
                    window.open("/go-search", "_blank");
                  }
                }}
                iconRight={
                  <ArrowRightLong
                    className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                  />
                }
              >
                Go to LO:LIVE
              </ButtonHomeMain>
              <ButtonHomeMain
                type="secondary"
                iconRight={
                  <ArrowRightLong
                    className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                    color={"rgb(254, 97, 97)"}
                  />
                }
                onClick={scrollToContact}
                data-testid="locl-home-main-space-info-button-brief"
              >
                Brief us
              </ButtonHomeMain>
            </div>

            <div className={styles["locl-home-main__location-events__single-item"]}>
              <h3 className={styles["locl-home-main__location-events__single-item__title"]}>
                Browse and book our popular activation&nbsp;spaces
              </h3>
              <LocationsEvents
                data={typesOfLocations}
                wrapperClass={styles["locl-home-main__location-events__left"]}
                isMobile={width < 870}
              />
            </div>
          </div>
        )}
        <div className={styles["locl-home-main__divider"]} />
        {!!placeImages.length && (
          <div className={styles["locl-home-main__looking-for__single-item"]} data-testid="locl-home-main-place-info">
            <HomeMainCarousel
              images={placeImages || []}
              isDesktop={isDesktop}
              wrapperName={styles["locl-home-main__carousel"]}
            />
            <h3 className={styles["locl-home-main__looking-for__single-item__title"]}>
              Bespoke support services
            </h3>
            <p
              ref={locEventsMaxTextHeightRef as LegacyRef<HTMLParagraphElement> | undefined}
              className={styles["locl-home-main__looking-for__single-item__text"]}
            >
              {isDesktop
                ? "We have an unrivalled resource of thousands of event spaces worldwide as a result of working across countless brand briefs in the last 10 years. We have catalogued the best locations and have a number of hidden gems (or unicorns as we call them). We have a network of scouts globally to help satisfy any event space need. From client and creative agency side, we understand your requirements from an aesthetic and production perspective - and will always find you the optimum space."
                : "With over 5000 closed and private locations, regardless of the size or complexity of your brief, send us your requirements and our team of location specialists will find you the perfect place."}
            </p>
            <ButtonHomeMain
              color="green"
              onClick={scrollToContact}
              iconRight={
                <ArrowRightLong
                  className={styles["locl-home-main__looking-for__single-item__button-icon"]}
                />
              }
              data-testid="locl-home-main-place-info-button-brief"
            >
              Brief us
            </ButtonHomeMain>
            <div className={styles["locl-home-main__location-events__single-item"]}>
              <h3 className={styles["locl-home-main__location-events__single-item__title"]}>
                See our location expertise in action
              </h3>
              <LocationsEvents
                data={typesOfEvents}
                wrapperClass="location-events-right"
                isMobile={width < 870}
              />
            </div>
          </div>
        )}
      </div>
      <div ref={brandRef}>
        <HomePageBrands customBrands={brandLogos} filterColor="rgba(243, 244, 246, 1)" />
      </div>
    </section>
  );
};

export default HomeMain;
