import classNames from "classnames";

// components
import Link from "@/components/Link";

// interfaces
import { HomeMainButtonProps } from "../HomeMain.interface";

// assets
import styles from "@/assets/styles/containers/home-main.module.scss";

const HomeMainButton = (props: HomeMainButtonProps) => {
  const {
    children,
    className,
    href,
    to,
    type = "primary",
    color = "red",
    iconLeft,
    iconRight,
    disabled,
    ...rest
  } = props;

  const btnClassName = classNames(
    className,
    styles["locl-home-main__button"],
    styles[`locl-home-main__button_type-${type}`],
    styles[`locl-home-main__button_color-${color}`]
  );

  if (href) {
    return (
      <a
        href={href}
        onClick={(e) => !!disabled && e.preventDefault()}
        data-testid="locl-home-main-button-link-href"
        {...rest}
        className={btnClassName}
      >
        {iconLeft}
        <span className={styles["locl-home-main__button_label"]}>{children}</span>
        {iconRight}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        href={to}
        onClick={(e) => !!disabled && e.preventDefault()}
        className={btnClassName}
        data-testid="locl-home-main-button-link-to"
        {...rest}
      >
        {iconLeft}
        <span className={styles["locl-home-main__button_label"]}>{children}</span>
        {iconRight}
      </Link>
    );
  }

  return (
    <button type="button" data-testid="locl-home-main-button" {...rest} className={btnClassName} disabled={disabled}>
      {iconLeft}
      <span className={styles["locl-home-main__button_label"]}>{children}</span>
      {iconRight}
    </button>
  );
};

export default HomeMainButton;
