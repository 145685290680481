export default {
  home: {
    banner: {
      welcome: "welcome to"
    }
  },
  footer: {
    contacts: {
      email: "hello@locationlive.com",
      phone: {
        url: "+4402089355033",
        label: "+44 (0)208 935 5033",
      },
      location: {
        url: "https://maps.app.goo.gl/w2QRyY8VXFG66YXA6",
        label: {
          firstLine: "The Courtyard, 37 Sheen Road",
          secondLine: "Richmond Upon Thames, TW9 1AJ"
        }
      }
    }
  },
} as const;
