export default {
  home: {
    banner: {
      welcome: "g’day, welcome to"
    }
  },
  footer: {
    contacts: {
      email: "gday@locationlive.com",
      phone: {
        url: "+610428359426",
        label: "+61 (0)428 359 426",
      },
      location: {
        url: "https://maps.app.goo.gl/v6iseKvfnbPjFJJX8",
        label: {
          firstLine: "Level 6/201 Kent St, Sydney NSW 2000"
        }
      }
    }
  },
} as const;
